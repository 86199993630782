<template>
  <v-container class="px-10">
    <div v-for="film in films" :key="film.id">
      <v-row>
        <v-col :cols="$store.state.isMobile ? 4 : 2">
          <v-img
            :src="film.image"
            :width="$store.state.isMobile ? 100 : 200"
            style="cursor: pointer"
            @click="openFilmDetails(film)"
          />
        </v-col>
        <v-col class="white--text">
          <v-chip label small color="orange"> Cinelandia </v-chip>
          <br />
          <span :class="!$store.state.isMobile ? 'title' : ''">{{ film.title }}</span>
          <br />
          <span>{{ film.startDate }}</span>
          <br />
          <span v-if="!$store.state.isMobile" class="body-1">{{ film.description }}</span>
        </v-col>
      </v-row>
    </div>
    <film-details-mobile
      :film-details="selectedFilm"
      :show-dialog="showFilmDialogMobile"
      @closeFilmDialog="showFilmDialogMobile = false"
    />
    <film-details
      :film-details="selectedFilm"
      :show-dialog="showFilmDialog"
      @closeDetailsDialog="showFilmDialog = false"
    />
    <v-overlay :value="showOverlay">
      <v-progress-circular color="red" size="100" indeterminate :opacity="1" />
    </v-overlay>
  </v-container>
</template>

<script>
import db from '../firebaseConfiguration/firebaseInit';
import FilmDetails from '../components/homeComponents/FilmDetails';
import FilmDetailsMobile from '../components/homeComponents/FilmDetailsMobile';
import moment from 'moment';

export default {
  components: {
    FilmDetails,
    FilmDetailsMobile,
  },
  data() {
    return {
      films: [],
      selectedFilm: {},

      showFilmDialog: false,
      showFilmDialogMobile: false,
      showOverlay: true,
    };
  },
  created() {
    this.getCinelandiaFilms();
  },
  methods: {
    getCinelandiaFilms() {
      let currentDate = moment().format('YYYY-MM-DD');

      db.firestore()
        .collection('film')
        .where('startDate', '>=', currentDate)
        .where('cinelandia', '==', true)
        .orderBy('startDate')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const datesOfTheCurrentWeek = [];
            let todaysDate = moment();
            let todaysDayOfYear = todaysDate.dayOfYear();

            doc.data().dates.forEach((date) => {
              let controlDate = new Date(1970, 0, 1);
              controlDate.setSeconds(date.day.seconds);
              let momentControlDate = moment(controlDate);
              let controlDayOfYear = momentControlDate.dayOfYear();

              if (controlDayOfYear >= todaysDayOfYear && controlDayOfYear <= todaysDayOfYear + 4) {
                datesOfTheCurrentWeek.push(date);
              }
            });

            const data = {
              id: doc.data().id,
              description: doc.data().description,
              type: doc.data().type,
              image: doc.data().image,
              length: doc.data().length,
              title: doc.data().title,
              startDate: doc.data().startDate,
              endDate: doc.data().endDate,
              posterImage: doc.data().backgroundImage,
              dates: datesOfTheCurrentWeek,
            };
            this.films.push(data);
          });
          this.showOverlay = false;
        });
    },
    openFilmDetails(film) {
      Object.assign(this.selectedFilm, film);
      if (this.$store.state.isMobile) this.showFilmDialogMobile = true;
      else this.showFilmDialog = true;
    },
  },
};
</script>

<style></style>
